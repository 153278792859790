import React, { Suspense, lazy } from 'react';
import { createTheme, Theme, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/system';
import { Route, Router, Routes } from 'react-router';
import Login from './pages/login/Login';
import NotFound from './components/navigation/NotFound';
import RequireAuth from './components/navigation/permissions/RequireAuth';
import { useWindowSize } from 'react-helpers';
import Loader from 'react-helpers/src/components/Loader';
import { DEFAULT_FONT_SIZE, MOBILE_FONT_SIZE } from './styles/styles';
import * as environment from './environments.json';
import useNavigationStore from './store/useNavigationStore';
import useNotistack from 'react-helpers/src/hooks/useNotistack';

const Root = lazy(() => import('./pages/Root'));

const App = () => {
    const { isMobile } = useWindowSize();
    const { railRoutes } = useNavigationStore();
    const { Toaster } = useNotistack();
    const theme = createTheme({
        typography: {
            fontFamily: ['Inter'].join(','),
            fontSize: isMobile ? MOBILE_FONT_SIZE : DEFAULT_FONT_SIZE,
        },
        palette: {
            mode: 'dark',
            background: {
                default: '#181818',
                paper: '#343434',
            },
            primary: {
                main: '#ffffff',
                contrastText: '#000000',
            },
            secondary: {
                main: '#F01CA0',
            },
            success: {
                main: '#A0F01C',
            },
            error: {
                main: '#ff3333',
            },
            info: {
                main: '#2C4770',
            },
            warning: {
                main: '#91A437',
            },
            text: {
                primary: '#fff',
                disabled: '#666',
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={<Loader />}>
                <Routes>
                    <Route path="login" element={<Login />} />
                    <Route path="/*" element={<Root />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
            <Toaster position="bottom-center" reverseOrder={false} />
        </ThemeProvider>
    );
};

export default App;
